import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Masonry from "react-masonry-css"
import PostCard from "../components/global/postCard"
import { Link, graphql, useStaticQuery } from "gatsby"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query AllProjects {
      allMarkdownRemark {
        nodes {
          frontmatter {
            imageAlt
            category
            title
            description
            date
            type
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  `)
  console.log(data)

  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    1024: 3,
    768: 2,
    640: 1,
  }
  return (
    <Layout>
      <Seo title="Contact me" />
      <div className="container mx-auto py-24">
        <div className={`mt-10`}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid gap-y-5"
            columnClassName="my-masonry-grid_column"
          >
            {data.allMarkdownRemark.nodes.map((post, i) => {
              if (post.frontmatter.type !== "project") return null
              else {
                return (
                  <div key={i}>
                    <Link to={post.fields.slug}>
                      <PostCard post={post} />
                    </Link>
                  </div>
                )
              }
            })}
          </Masonry>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
